// eslint-disable-next-line import/order
import styles from './styles.module.css';
import React, { useMemo } from 'react';
import { DataType, IntegrationType } from '@common/entities';
import { SelectDataDestField } from '@components/SelectDataDestField/SelectDataDestField';
import { SelectSourceFieldPopup } from '@components/SelectSourceFieldPopup/SelectSourceFieldPopup';
import { ActionType } from '@pages/TaskDetails/components/ActionTypeSelect/ActionTypeSelect';
import { FieldInput } from '@components/FieldInput/FieldInput';
import { BitrixDataType } from '@common/bitrix/entities';
import useDataDestFields from '@hooks/data/useDataDestFields';
import { Loader } from '@components/Loader/Component';


export type TemplateBindingData = {
    destField: string;
    value: string;
};

type Props = {
    dataType: DataType;
    sourceIntegrationType: IntegrationType;
    sourceIntegrationId: string;
    actionType: ActionType;
    destIntegrationType: IntegrationType;
    destIntegrationId: string;
    binding: TemplateBindingData;
    setBinding: (value: TemplateBindingData) => void;
};

export const TemplateBinding: React.FC<Props> = (
        {
            dataType,
            sourceIntegrationType,
            sourceIntegrationId,
            actionType,
            destIntegrationType,
            destIntegrationId,
            binding,
            setBinding
        }
) => {
    // Hooks call
    const { fields, isExecuting } = useDataDestFields(destIntegrationType, destIntegrationId, actionType);
    const selectedField = useMemo(() => fields?.find(f => f.id === binding.destField), [fields, binding.destField]);
    // --

    if (isExecuting) {
        return <Loader />;
    }

    const setValue = (value: string) => {
        setBinding({ ...binding, value: value });
    };

    const setDestField = (value: string) => {
        setBinding({ ...binding, destField: value });
    };

    const addSourceField = (field: string): void => {
        const newValue = `${binding.value ?? ''}${field}`;
        setValue(newValue);
    };

    return (
        <div className={styles.container}>
            <p>Поле назначения</p>
            <SelectDataDestField
                integrationType={destIntegrationType}
                integrationId={destIntegrationId}
                actionType={actionType}
                field={binding.destField}
                setField={setDestField}
            />
            <p>Значение</p>
            <FieldInput
                integrationType={destIntegrationType}
                integrationId={destIntegrationId}
                fieldType={BitrixDataType.LEADS} // TODO разобраться
                fieldData={selectedField}
                value={binding.value}
                setValue={setValue}
                manualInput
            />
            <SelectSourceFieldPopup
                integrationType={sourceIntegrationType}
                integrationId={sourceIntegrationId}
                fieldType={dataType}
                setField={addSourceField}
            />
        </div>
    );
};
